/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Nav, Navbar, NavDropdown, Container, Button } from "react-bootstrap";
import "../scss/styles.scss";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import logo from "../images/logo.svg";
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
        <Navbar.Brand href="/">
          <img
            src={logo}
            width="48"
            height="48"
            className="d-inline-block align-top"
            alt="Rhylla Devon logo"
            style={{
              marginLeft: 8,
              marginRight: 16,
            }}
          />
          <div>{data.site.siteMetadata.title}</div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav justify-content-end">
          <Nav className="mr-auto"></Nav>
          <Nav className="justify-content-end">
            <Link className="nav-link" to="/about-us">
              About Us
            </Link>

            <NavDropdown title="Accommodation" id="collasible-nav-dropdown">
              <Link
                className="dropdown-item"
                to="/accommodation/rhylla-cottage"
              >
                Rhylla Cottage
              </Link>
              <Link
                className="dropdown-item"
                to="/accommodation/rhylla-retreat"
              >
                Rhylla Retreat
              </Link>
            </NavDropdown>
            <Link className="nav-link" to="/contact-us">
              <Button variant="warning" style={{ fontWeight: 500 }}>
                Contact Us
              </Button>
            </Link>
            <div id="social-links">
              <a
                id="facebook-link"
                className="nav-link"
                href="https://www.facebook.com/Rhylla-Devon-102076861602837/"
                style={{ paddingTop: 4, alignSelf: "centre" }}
              >
                <FaFacebook style={{ width: 32, height: 32 }} />
              </a>
              <a
                className="nav-link"
                href="https://www.instagram.com/rhylladevon"
                style={{ paddingTop: 4, alignSelf: "centre" }}
              >
                <FaInstagram style={{ width: 32, height: 32 }} />
              </a>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <main>
        <Container fluid>{children}</Container>
      </main>
      <footer style={{ marginTop: 32, marginBottom: 16, textAlign: "right" }}>
        <Container fluid>
          © {new Date().getFullYear()}, Built by Rhylla Devon
        </Container>
      </footer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
